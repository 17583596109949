<template>
  <div class="textarea-input-wrapper">
    <InputLabel :id="id" :text="labelText" :mandatory="mandatory" />
    <textarea
      class="textarea"
      :placeholder="placeholder"
      v-model="value"
      @[processOn]="onInput($event.target.value)"
    />
    <div class="validation-message-wrapper" v-if="shouldValidate">
      <InputValidationMessage :message="validationMessage" />
    </div>
  </div>
</template>

<script>
import { generateUID } from "../../utils/generateUID";

import InputLabel from "../elements/InputLabel";
import InputValidationMessage from "../elements/InputValidationMessage";

export default {
  name: "TextAreaInput",
  props: {
    name: String,
    labelText: String,
    mandatory: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    processOn: {
      type: String,
      default: "input",
    },
    shouldValidate: {
      type: Boolean,
      default: true,
    },
    validationMessage: String,
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      id: generateUID(),
      value: this.modelValue,
    };
  },
  methods: {
    onInput(value) {
      this.$emit("update:modelValue", value);
    },
  },
  computed: {
    isValid: function () {
      return !this.validationMessage;
    },
  },
  watch: {
    value: function () {
      this.$emit("update:modelValue", this.value);
    },
  },
  components: {
    InputLabel,
    InputValidationMessage,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.textarea-input-wrapper {
  width: 100%;

  .textarea {
    max-width: 630px;
    width: 100%;
    height: 150px;
    border: 1px solid $main-grey;
    border-radius: 10px;
    padding: 0 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    box-sizing: border-box;
    padding: 23px 25px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    &::placeholder {
      color: $main-grey;
      font-weight: bold;
      margin-top: 25px;
    }

    &:focus {
      border-color: $template-color;
    }
  }
}
</style>
