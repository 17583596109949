<template>
  <PrimaryTemplate
    :step="step"
    :buttonFunction="submit"
    :mainTitle="'Perhaps, you wanted to add something?'"
    :needBackButton="true"
    :backFunction="back"
    :nextButtonText="'Send'"
    :loading="loading"
  >
    <TextAreaInput
      name="extraInfo"
      labelText="What do you want to add?"
      placeholder="Type your message here"
      v-model="extraInfo"
    />

    <UploaderComponent :title="'Attach file'" v-model="files" />
    <div v-if="errorMessage" class="error-message red">
      {{ errorMessage }}
    </div>
  </PrimaryTemplate>
</template>
<script>
import PrimaryTemplate from "@/layouts/PrimaryTemplate.vue";
import TextAreaInput from "@/components/utility/TextAreaInput.vue";
import UploaderComponent from "@/components/utility/UploaderComponent.vue";
import { sendCV, sendfile, update } from "../api/formdata";

export default {
  name: "ExtraInfo",
  data() {
    return {
      step: "ExtraInfo",
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    extraInfo: {
      get() {
        return this.$store.getters.getExtraInfo;
      },
      set(value) {
        this.$store.dispatch("setLastStepField", {
          field: "extraInfo",
          value: value,
        });
      },
    },
    files: {
      get() {
        return this.$store.getters.getFiles;
      },
      set(value) {
        this.$store.dispatch("setLastStepField", {
          field: "files",
          value: value,
        });
      },
    },
  },
  methods: {
    async submit() {
      let modifyLoading = this.modifyLoading;
      let setError = this.setError;
      let router = this.$router;

      modifyLoading(true);
      setError(null);

      let thisData = {
        extraInfo: this.extraInfo,
      };
      let currentData = JSON.parse(localStorage.getItem("data"));
      currentData.data.push(thisData);

      await update(currentData).then(async (response) => {
        if (response.status >= 200 && response.status <= 300) {
          if (this.files.length > 0) {
            let file = this.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async function () {
              await sendfile(
                currentData.id,
                reader.result,
                file.name,
                file.type
              ).then(async (response) => {
                if (response.status >= 200 && response.status <= 300) {
                  await sendCV(currentData.id).then((response) => {
                    if (response.status >= 200 && response.status <= 300) {
                      modifyLoading(false);

                      localStorage.removeItem("data");
                      router.push({ path: "/answers-saved" });
                    } else {
                      modifyLoading(false);
                      if (response.status == 400) {
                        setError("There was an error submitting the form. Please try again.");
                      } else {
                        setError("There was an error submitting the form. Please try again.");
                      }
                      console.log("Error: ");
                      console.log(response.status);
                    }
                  });
                } else {
                  modifyLoading(false);
                  if (response.status == 400) {
                    setError(
                      "Validation error while submitting the file. Please check the format and size correctness!"
                    );
                  } else {
                    setError(
                      "Form submission error! Please try again later!"
                    );
                  }
                  console.log("Error: ");
                  console.log(response.status);
                }
              });
            };
            reader.onerror = function (error) {
              setError("file read error!");
              console.log("Error reading file: ", error);
            };
          } else {
            await sendCV(currentData.id).then((response) => {
              if (response.status >= 200 && response.status <= 300) {
                modifyLoading(false);

                localStorage.removeItem("data");
                router.push({ path: "/answers-saved" });
              } else {
                modifyLoading(false);
                if (response.status == 400) {
                  setError("Form submission error! Please try again later!");
                } else {
                  setError("Form submission error! Please try again later!");
                }
                console.log("Error: ");
                console.log(response.status);
              }
            });
          }
        } else {
          modifyLoading(false);
          if (response.status == 400) {
            this.errorMessage =
              "Validation error! Please check the accuracy of the entered data!";
          } else {
            this.errorMessage =
              "Form submission error! Please try again later!";
          }
          console.log("Error: ");
          console.log(response.status);
        }
      });
    },
    back() {
      let currentData = JSON.parse(localStorage.getItem("data"));
      currentData.data.pop();
      let thisDataStringified = JSON.stringify(currentData);
      localStorage.setItem("data", thisDataStringified);

      this.$router.push({ path: "/technology-stack" });
    },
    modifyLoading(loading) {
      this.loading = loading;
    },
    setError(message) {
      this.errorMessage = message;
    },
  },
  components: {
    PrimaryTemplate,
    TextAreaInput,
    UploaderComponent,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.textarea-input-wrapper {
  margin-bottom: 10px;
}
.red {
  color: red;
}
:disabled {
  opacity: 0.4;
}
</style>
